/** @jsx jsx */
import { mapColorOptionsWithAvailability } from '../util'
import { FC, useRef, useCallback, useMemo, Fragment, useState } from 'react'
import { jsx, Box, Text } from 'theme-ui'
import { Maybe, ProductItemComponentType } from '~/@types/models'
import { AddToCartButton } from '~/components/add-to-card-button'
import ColorTitle from '~/components/parameters/color-parameter/color-title'
import OptionsList from '~/components/parameters/color-parameter/options-list'
import TitleSingle from '~/components/parameters/color-parameter/title-single'
import { ColorOption, Option, SelectedValue } from '~/components/parameters/color-parameter/types'
import YardageInput from '~/components/product-detail-panel/yardage-input'
import { ColorBoxV2 } from '~/componentsV2/colors/colorBox'
import { space } from '~/gatsby-plugin-theme-ui'
import useLinkState from '~/hooks/use-link-state'
import useMediaQuery from '~/hooks/use-media-query'

type GroupedColorOptions = {
  standard: readonly ColorOption[]
  custom: readonly ColorOption[]
}

type Props = {
  colorOptions: ColorOption[]
  option: Option
  onChange: () => void
  value: SelectedValue
  title?: Maybe<string>
  titleCustom: string
  variant: any
  customAttributes: any
  disabled: any
  label: any
  product: ProductItemComponentType
  productType?: string
}

const ColorParameter: FC<Props> = ({
  colorOptions: colorOptionsWithoutAvailbility,
  option,
  onChange,
  value,
  title,
  product,
  variant,
  customAttributes,
  productType,
  disabled,
  label,
}) => {
  const [quantity, setQuantity] = useState<number>(3)
  const handleChangeQuantity = useCallback(amount => setQuantity(amount), [])
  const ref = useRef<HTMLDivElement>(null)

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-device-width: ${breakpoints[2]})`,
  })

  // const colorOptions = colorOptionsWithoutAvailbility
  const colorOptions = mapColorOptionsWithAvailability(
    product,
    colorOptionsWithoutAvailbility,
    variant
  )

  const onHoverOption = useCallback(
    (ref, colorOption, show = true) => {
      if (!isMobile) {
        if (!ref.current) {
          return
        }

        const optionTitle = ref.current.querySelector(`[data-title="${colorOption.title}"]`)
        const prevOptionTitle = ref.current.querySelector(`[data-title="${value}"]`)

        if (optionTitle === prevOptionTitle) {
          return
        }

        if (optionTitle) {
          optionTitle.style.display = show ? 'inline-flex' : 'none'
        }

        if (prevOptionTitle) {
          prevOptionTitle.style.display = !show ? 'inline-flex' : 'none'
        }
      }
    },
    [value, ref]
  )

  const groupedOptions = useMemo<Maybe<GroupedColorOptions>>(() => {
    if (!colorOptions) {
      return null
    }

    return {
      standard: colorOptions,
      custom: colorOptions.filter(colorOption => colorOption.isCustom),
    }
  }, [colorOptions])

  const isCustomOptionsTitleVisible = product.productType !== 'Fabric'
  const isFabric = product.productType === 'Fabric'
  const { comesFromSaleCollections } = useLinkState()

  if (option.values.length === 1) {
    return (
      <Box className="color-parameter single">
        <TitleSingle option={option} title={title} />
        {/* {groupedOptions && groupedOptions.standard[0] && (
          <ColorBoxV2
            value={groupedOptions.standard[0].title}
            selectedValue={groupedOptions.standard[0].title}
            currentlyNotInStock={groupedOptions.standard[0].currentlyNotInStock}
            src={groupedOptions.standard[0].image}
            alt={groupedOptions.standard[0].title}
          />
        )} */}
      </Box>
    )
  }

  return (
    <Box className="color-parameter">
      <ColorTitle
        ref={ref}
        colorOptions={colorOptions}
        option={option}
        selectedValue={value}
        isFabric={isFabric}
      />
      {isFabric && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <YardageInput onChange={handleChangeQuantity} containerSx={{ width: '50%' }} />
          <AddToCartButton
            title={product.title}
            variant={variant}
            initialQuantity={quantity}
            customAttributes={customAttributes}
            sxProps={{
              minWidth: '48% !important',
              overflow: 'hidden',
              alignSelf: 'end',
              width: '48%',
              height: 42,
            }}
            disabled={disabled}
            label={label}
            productType={productType}
          />
        </Box>
      )}
      {groupedOptions && (
        <Fragment>
          <OptionsList
            options={[...groupedOptions.standard]}
            listTitle={title}
            // @ts-ignore
            // onHoverOption={onHoverOption}
            onChange={onChange}
            option={option}
            selectedValue={value}
            optionRef={ref}
          />

          {/* {!comesFromSaleCollections && groupedOptions.custom.length > 0 && (
            <Box
              sx={{
                mt: [space.md2],
              }}
            >
              <OptionsList
                options={[...groupedOptions.custom]}
                // @ts-ignore
                listTitle={
                  !isFabric && (
                    <Fragment>
                      Recommended custom options.{' '}
                      <Text
                        as="span"
                        sx={{
                          display: `block`,
                          fontSize: `inherit`,
                        }}
                      >
                        Lead-time based on product and availability
                      </Text>
                    </Fragment>
                  )
                }
                listTitleSx={{
                  display: isCustomOptionsTitleVisible ? 'block' : 'none',
                }}
                onHoverOption={onHoverOption}
                onChange={onChange}
                option={option}
                selectedValue={value}
                optionRef={ref}
              />
            </Box>
          )} */}
        </Fragment>
      )}
    </Box>
  )
}

export default ColorParameter
