/** @jsx jsx */
// @ts-ignore
import { Fragment, FC, useState, useCallback, useEffect, useMemo } from 'react'
import { jsx, Box } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'

import { AddToCartButton } from '~/components/add-to-card-button'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'

import { formatMoney } from '~/utils/format'
import PreOrderNote from '~/components/product-detail-panel/pre-order-note'
import ProductVariantPriceNote from '~/components/product-variant-price-note'
import useProductAvailability from '~/hooks/use-product-availability'
import { useFlyOutContext } from '~/containers/fly-out/contex'
import { Tag } from '~/componentsV2/tag'
import { FONTS } from '~/styled/components/typography'

const isRug = (tags: readonly string[]) => tags && tags.includes('product-rug')

type PreorderToggleType = {
  swatch: ProductVariantType
}

export const usePreorderToggle = ({ swatch }: PreorderToggleType) => {
  const [preOrderAgreement, setPreorderAgreement] = useState<boolean>(false)

  useEffect(() => {
    setPreorderAgreement(false)
  }, [swatch])

  const handlePreorderAgreement = useCallback(() => {
    setPreorderAgreement(() => !preOrderAgreement)
  }, [setPreorderAgreement, preOrderAgreement])

  return {
    preOrderAgreement,
    handlePreorderAgreement,
  }
}

type SingleSwatchProps = {
  product: ProductItemComponentType
  variant: ProductVariantType
}

const SingleSwatch = ({ variant, product }: SingleSwatchProps) => {
  const variantNotInStock = variant.currentlyNotInStock
  const [isFirstClick, setIsFirstClick] = useState<boolean>(() => true)
  const { preOrderAgreement, handlePreorderAgreement } = usePreorderToggle({
    swatch: variant,
  })

  const { preOrderMessage } = useProductAvailability({
    variant,
    product,
  })

  const onClick = useCallback(() => {
    if (isFirstClick) {
      setIsFirstClick(false)
    }
  }, [handlePreorderAgreement, preOrderAgreement, isFirstClick])

  // todo - abstract
  const customAttributes = []
  if (preOrderMessage) {
    customAttributes.push({
      key: 'Pre-order',
      value: String(preOrderMessage).toString(),
    })
  }

  const isButtonDisabled = !isFirstClick && variantNotInStock && !preOrderAgreement

  return (
    <Fragment>
      <AddToCartButton
        // sx={{ width: '100%', height: '42px', mt: '5px' }}
        title={variant.title}
        productType={product.productType}
        variant={variant}
        initialQuantity={1}
        sxProps={{
          variant: 'buttons.secondary',
          mt: [space.md2],
          width: '100%',
        }}
        buttonType="tag"
        disabled={isButtonDisabled}
        label="SWATCH"
        // { variantNotInStock ? `Pre-Order Swatch` : `Order Swatch`}
        customAttributes={customAttributes}
        {...(isFirstClick &&
          variantNotInStock && {
            onClick,
          })}
      />
      {/* {variantNotInStock && !isFirstClick && (
        <Fragment>
          <ProductVariantPriceNote
            product={product}
            variant={variant}
            sx={{
              my: [space.gridGap1],
              display: 'block',
            }}
          />
          <PreOrderNote onChange={handlePreorderAgreement} value={preOrderAgreement} />
        </Fragment>
      )} */}
    </Fragment>
  )
}

export interface OrderSwatchProps {
  product: ProductItemComponentType
  variant: ProductVariantType
  swatches: any[]
  onCustomCart?: () => void
  shouldBeOpenFlyOut?: boolean
}

const OrderSwatch = ({
  product,
  variant,
  swatches,
  onCustomCart,
  shouldBeOpenFlyOut,
}: OrderSwatchProps) => {
  const { openFlyOut } = useFlyOutContext()

  const disableOrderSwatch = product.metafields.disableOrderSwatch
  const enableOrderSwatch = !disableOrderSwatch && swatches
  const hasSwatch =
    product.variants && product.variants.find(variant => variant.titleSize === 'Swatch')

  const selectedSwatch = useMemo(() => {
    const swatch = swatches.find(
      productSwatch => productSwatch.title === variant.selectedOptions[0].value
    )

    return swatch
  }, [variant])

  const cheapestVariant = selectedSwatch ? selectedSwatch?.cheapestVariant : {}
  const swatchPrice = cheapestVariant?.price ? cheapestVariant?.price.amount : null

  const { preOrderAgreement, handlePreorderAgreement } = usePreorderToggle({
    swatch: selectedSwatch && cheapestVariant,
  })

  const { preOrderMessage } = useProductAvailability({
    variant: cheapestVariant,
    product,
  })

  const { currentlyNotInStock } = cheapestVariant || {}
  const customAttributes = []
  if (preOrderMessage && currentlyNotInStock) {
    customAttributes.push({
      key: 'Pre-order',
      value: String(preOrderMessage).toString(),
    })
  }

  if (isRug(product.tags) && hasSwatch) {
    return <SingleSwatch product={product} variant={hasSwatch} />
  }

  if (!enableOrderSwatch) {
    return null
  }

  if (shouldBeOpenFlyOut) {
    return (
      <Tag
        style={{
          padding: '3px',
          cursor: 'pointer',
        }}
        bgColor="transparent"
        onClick={() => {
          openFlyOut()
        }}
      >
        <Box
          sx={{
            fontFamily: FONTS.Helvetica,
            padding: '5px 10px',
            backgroundColor: '#EDEDED',
          }}
        >
          SWATCH
        </Box>
      </Tag>
    )
  }

  return (
    <Fragment>
      <AddToCartButton
        // sx={{ width: '100%', height: '100%', mt: '5px' }}
        title={selectedSwatch?.title}
        variant={cheapestVariant}
        customAttributes={customAttributes}
        productType={product.productType}
        buttonVariant="secondary"
        buttonType="tag"
        label="SWATCH"
        // label={`${
        //   'SWATCH'
        //   // currentlyNotInStock ? `PRE-ORDER SWATCH` : `ORDER SWATCH`
        //   // : swatchPrice === '0.0'
        //   // ? `ORDER SWATCH`
        //   // : `Order Swatch - ${formatMoney(swatchPrice)}`
        // }`}
        disabled={currentlyNotInStock && !preOrderAgreement}
        onCustomCart={onCustomCart}
      />
      {/* <Box sx={{ my: 24 }}>
        {selectedSwatch && (
          <Fragment>
            {currentlyNotInStock && (
              <Fragment>
                <ProductVariantPriceNote
                  product={product}
                  variant={cheapestVariant}
                  sx={{
                    mb: [space.gridGap1],
                    display: 'block',
                  }}
                />
                <PreOrderNote onChange={handlePreorderAgreement} value={preOrderAgreement} />
              </Fragment>
            )}
          </Fragment>
        )}
      </Box> */}
    </Fragment>
  )
}

export default OrderSwatch
