/** @jsx jsx */
import { jsx, Grid, Box, Text, BoxProps, SxStyleProp } from 'theme-ui'
import { FC } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'
import urls from '~/common/urls'
import ColorSwatch from '~/components/product-detail-panel/color-swatch'
import { Color } from '~/components/product-detail-panel/types'
import useLinkState from '~/hooks/use-link-state'

type Props = BoxProps & {
  colors: readonly Color[]
  sxProps?: SxStyleProp
  productHandle?: string
}

const ColorsList: FC<Props> = ({ colors: colorsBase, sxProps, productHandle }) => {
  const { comesFromSaleCollections } = useLinkState()
  const colorsOnSale: readonly Color[] =
    colorsBase.filter(
      color => color.cheapestVariant?.price?.amount < color.cheapestVariant?.compareAtPrice?.amount
    ) || []

  const colors = comesFromSaleCollections ? colorsOnSale : colorsBase

  /**
   * do we want an active state?
   * .css-ri42mv-ImageWrapper {
        box-sizing: border-box;
        padding: 2px;
        border: 1px solid #2B2B2B;
    }
   */

  if (colors.length === 0) {
    return null
  }

  return (
    <Box sx={sxProps} className="colors-list">
      {/* <Text
        sx={{
          textTransform: `uppercase`,
          color: `text`,
          display: `block`,
          mb: [space.gridGap1],
        }}
      >
        More colors
      </Text> */}
      <Grid columns={[6]} gap={[space.sm, null, null, space.gridGap1]}>
        {colors.map((color, idx) => (
          <ColorSwatch
            key={color.title + idx}
            // @ts-ignore
            color={color}
            active={color.handle === productHandle}
            to={`${urls.pages.productDetail}/${color.handle}`}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default ColorsList
