/** @jsx jsx */
import { jsx, Flex, Text, Box } from 'theme-ui'
import useFiles from '~/containers/product-detail/product-details-section/use-files'
import Files from '~/containers/product-detail/product-details/files'
import { ProductDetailsTab } from '~/containers/product-detail/product-details/types'
import { space } from '~/gatsby-plugin-theme-ui'

const SpecsTab = ({ product, variant }: ProductDetailsTab) => {
  const variantSku = variant.sku

  const productSpecs = product.metafields.productSpecsFabricContent
  const productSpecsPileHeight = product.metafields.productSpecsPileHeight
  const productSpecsSizes = product.metafields.productSpecsSizes
  const productSpecsCoo = product.metafields.productSpecsCoo
  const productSpecsGoodweave = product.metafields.productSpecsGoodweave
  const selectedColor = variant?.selectedOptions?.find(variant => variant.name === 'Color')?.value

  const { files } = useFiles({
    product,
    selectedColor,
  })

  const antiqueRug = product?.productType === 'Antique Rugs' ? true : false

  const link = []
  if (antiqueRug) {
    link.push({ href: product.metafields?.antiquesPdfLink, title: 'TEARSHEET' })
  }

  return (
    <Box py={space.tabContent}>
      <Flex
        as="ul"
        sx={{
          fontSize: '16px',
          flexDirection: 'column',
        }}
      >
        {productSpecs && <li>{productSpecs}</li>}
        {productSpecsPileHeight && <li>{productSpecsPileHeight}</li>}
        {productSpecsSizes && <li>{productSpecsSizes}</li>}
        {productSpecsCoo && <li>{productSpecsCoo}</li>}
        {productSpecsGoodweave && <li>{productSpecsGoodweave}</li>}
      </Flex>
      {variantSku && (
        <Text
          sx={{
            fontSize: 16,
            mt: [space.gridGap1],
            ml: [20],
          }}
        >
          SKU: {variantSku}
        </Text>
      )}
      {files.length > 0 && (
        <Box
          sx={{
            fontSize: 16,
            mt: [space.md2, null, 25],
            pl: 14,
          }}
        >
          <Files links={!antiqueRug ? files : link} />
        </Box>
      )}
    </Box>
  )
}

export default SpecsTab
