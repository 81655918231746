import {
  ColorOption,
  ColorOptionWithAvailability,
  FinishOption,
  FinishOptionWithAvailability,
} from './color-parameter/types'
import { ProductItemComponentType, ProductVariantTypeV2 } from '~/@types/models'

export const mapColorOptionsWithAvailability = (
  product: ProductItemComponentType,
  colorOptions?: Array<ColorOption | FinishOption>,
  variant?: ProductVariantTypeV2[],
  onlyCompareAtPriceVariants?: boolean
): Array<ColorOptionWithAvailability | FinishOptionWithAvailability> | null => {
  if (!colorOptions) {
    return null
  }

  if (!variant) {
    return colorOptions.map(option => ({
      ...option,
      currentlyNotInStock: false,
    }))
  }

  const titleSize = variant.titleSize

  return colorOptions.map(option => {
    const value = option.title
    const variant = product.variants.find(variant =>
      variant.selectedOptions.some(selectedOption => {
        if (titleSize) return selectedOption.value === value && variant.titleSize === titleSize
        return selectedOption.value === value
      })
    )

    return {
      ...option,
      currentlyNotInStock: Boolean(variant?.currentlyNotInStock),
      hideColor: onlyCompareAtPriceVariants ? !Boolean(variant?.compareAtPrice) : false,
    }
  })
}
