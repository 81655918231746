/** @jsx jsx */
import { useEffect } from 'react'
import { Box, jsx } from 'theme-ui'

interface Props {
  value?: string | number
}

declare const affirm: {
  ui: {
    ready: (callback: () => void) => void
    refresh: () => void
  }
}

export const AffirmSnippet = ({ value }: Props) => {
  const toCents = (value?: string | number) => {
    if (!value) {
      return
    }
    if (typeof value === 'number') {
      return value * 100
    }
    return parseInt(value.replace('$', '').replace(',', '')) * 100
  }

  useEffect(() => {
    if (!value) return

    // console.log('currentPrince', currentPrince.finalPrice.replace('$', '').replace(',', '') * 100)
    affirm.ui.ready(function () {
      affirm.ui.refresh()
    })
  }, [value])

  return (
    <Box
      sx={{
        fontFamily: 'body',
        textTransform: 'uppercase',
        fontSize: '12px',
        a: {
          color: 'primary',
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }}
    >
      <p className="affirm-as-low-as" data-page-type="product" data-amount={toCents(value)}></p>
    </Box>
  )
}
