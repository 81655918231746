import React from 'react'
import { Box } from 'theme-ui'
import { ProductItemComponentType } from '~/@types/models'
import { AddToCartButton } from '~/components/add-to-card-button'
import ColorParameter from '~/components/parameters/color-parameter'
import SelectParameter from '~/components/parameters/select-parameter'
import useProductAvailability from '~/hooks/use-product-availability'
import { isRugProduct } from '~/utils/product'

enum OptionType {
  Size = 'Size',
  Color = 'Color',
  Finish = 'Finish',
  Type = 'Type',
  Material = 'Material',
}

const renderParameter = (
  product: ProductItemComponentType,
  option: any,
  value: any,
  onChange: any,
  variant: any,
  options: any,
  title: any,
  customAttributes: any,
  initialQuantity: any,
  disabled: any,
  label: any,
  showAddToCart?: any
) => {
  // eslint-disable-next-line
  const { getColorParameterTitle } = useProductAvailability({
    product,
    variant,
  })

  switch (option.name) {
    case OptionType.Color:
      return (
        <ColorParameter
          productType={product.productType}
          product={product}
          // @ts-ignore
          colorOptions={product.colorOptions}
          option={option}
          onChange={onChange}
          value={value}
          title={isRugProduct(product) ? '' : getColorParameterTitle()}
          variant={variant}
          customAttributes={customAttributes}
          disabled={disabled}
          label={label}
        />
      )
    case OptionType.Finish:
      return (
        <ColorParameter
          product={product}
          // @ts-ignore
          colorOptions={product.finishOptions}
          option={option}
          productType={product.productType}
          onChange={onChange}
          value={value}
          title={isRugProduct(product) ? '' : getColorParameterTitle()}
        />
      )
    // case OptionType.Size:
    //   return (
    //     <Box
    //       id="select"
    //       sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    //     >
    //       <SelectParameter
    //         // @ts-ignore
    //         sx={{ minWidth: '46%' }}
    //         option={option}
    //         onChange={onChange}
    //         value={value}
    //         options={options}
    //       />
    //     </Box>
    //   )
    default:
      return (
        <Box className="select-parameter">
          <SelectParameter
            // @ts-ignore
            option={option}
            onChange={onChange}
            value={value}
            options={options}
          />
        </Box>
      )
  }
}

export default renderParameter
