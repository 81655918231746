/** @jsx jsx */
// @ts-ignore
import { jsx, Flex, Text, SxStyleProp, Box } from 'theme-ui'
import React, { FC, Fragment, ElementType, Ref } from 'react'
import {
  SelectedValue,
  ColorOption,
  Option,
  ColorOptionWithAvailability,
} from '~/components/parameters/color-parameter/types'
import ShippingLabel from '~/components/parameters/shipping-label'
import { ColorBoxV2 } from '~/componentsV2/colors/colorBox'

type Props = {
  listTitle: string
  listTitleSx?: SxStyleProp
  options: readonly ColorOptionWithAvailability[]
  selectedValue: SelectedValue
  onHoverOption: (optionRef: Ref<HTMLDivElement>, colorOption: ColorOption, out?: boolean) => void
  onChange: (colorOption: Option, title: string) => void
  optionRef: Ref<HTMLDivElement>
  option: Option
}

const OptionsList: FC<Props> = ({
  options,
  listTitle,
  listTitleSx,
  selectedValue,
  onHoverOption,
  onChange,
  optionRef,
  option,
}) => {
  // const preOrder =
  //   typeof listTitle === 'string' ? listTitle.indexOf('Expected to Ship Expected') > -1 : false
  // const checkPreOrder = (currentlyNotInStock: boolean) => {
  //   if (preOrder && currentlyNotInStock) {
  //     return false
  //   } else if (preOrder) {
  //     return false
  //   }
  //
  //   return currentlyNotInStock
  // }

  return (
    <Fragment>
      {/* <ShippingLabel
        sx={{
          mt: 0,
          minHeight: 24,
          ...listTitleSx,
        }}
      >
        {listTitle}
      </ShippingLabel> */}
      <Flex
        sx={{
          flexWrap: 'wrap',
          mt: 'sm',
          gap: '10px',
          position: 'relative',
        }}
      >
        {options.map(colorOption => {
          return (
            <ColorBoxV2
              key={`color-box-${Math.random()}`}
              value={colorOption.title}
              selectedValue={selectedValue}
              currentlyNotInStock={colorOption.currentlyNotInStock}
              onMouseOver={() => {
                if (onHoverOption) {
                  onHoverOption(optionRef, colorOption)
                }
              }}
              onMouseLeave={() => {
                if (onHoverOption) {
                  onHoverOption(optionRef, colorOption, false)
                }
              }}
              onClick={() => onChange(option, colorOption.title)}
              src={colorOption.image}
              alt={String(colorOption.title)}
            />
          )
        })}
      </Flex>
    </Fragment>
  )
}

export default OptionsList
