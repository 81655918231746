/** @jsx jsx */
import { jsx, Heading, Text, Box, Image } from 'theme-ui'
import { FC } from 'react'
import { ImageType, ProductItemComponentType, ShopifyImageSource } from '~/@types/models'
import { Link } from 'gatsby'
import ImageWrapper from '~/components/image-wrapper'
import theme from '~/gatsby-plugin-theme-ui'
import useProductVariantInfo from '~/hooks/use-product-variant-info'
import urls from '~/common/urls'
import useMainProductImage from '~/hooks/use-main-product-image'
import CompareAtPrice from '~/components/compare-at-price'
import { addScaleClasses, isNoScale, imageClassByType, scaleImageStyles } from '~/utils/product'

type Props = {
  variant?: string
  product: ProductItemComponentType & {
    hidePrice?: boolean
  }
  imageType?: 'productLifestyle' | 'productOverhead' | 'productFront'
  image?: ShopifyImageSource
}

const imageSizeMap: Record<string, string> = {
  FULLSCREEN: 'productLifestyle',
  OVERHEAD: 'productOverhead',
  FRONT: 'productFront',
}

const ProductItemSecondary: FC<Props> = props => {
  const { variant = 'primary', product, imageType = 'productFront', image } = props

  const { priceLabel, compareAtPrice } = useProductVariantInfo({ product, isStartingAt: true })
  const imageSizesFromProduct = useMainProductImage({
    product,
  })
  const { hidePrice } = product

  const mainImage = image || imageSizesFromProduct.mainImage
  const type = imageType || imageSizesFromProduct.type

  const applyScale = !isNoScale(product)

  return (
    <Box
      id="productItem"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: [25, 25, 25, 25, 25],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bg: 'bg',
          width: ['90%', '90%'],
          // pt: 35
          // minHeight: 240
        }}
      >
        {mainImage && (
          <Link
            // sx={applyScale ? scaleImageStyles : null}

            to={`${urls.pages.productDetail}/${product.handle}`}
            // className={`${applyScale ? addScaleClasses(product) : ``}`}
          >
            <Image
              src={mainImage.srcMobile}
              loading="lazy"
              sx={{
                aspectRatio: '303/209',
                height: 'auto !important',
                maxHeight: ['235px', '100%'],
                objectFit: 'cover',
                p: '5px',
              }}
              className={`${imageClassByType(product)} ${product.handle}`}
              variant={type || imageSizeMap[type]}
              alt={mainImage.altText || product.title || 'Product Image'}
            />
          </Link>
        )}
      </Box>
      <Box
        as="header"
        sx={{
          zIndex: 1,
          display: 'block',
          textAlign: variant === 'ternary' ? 'left' : 'center',
          // px: theme.space.md1,
          // // pt: compareAtPrice ? '39.75px' : theme.space.md3 * 1.5,
        }}
      >
        <Heading
          as="h3"
          sx={{
            mb: theme.space.sm * 1.5,
            fontSize: ['23px !important'],
            color: '#2b2b2b',
          }}
        >
          {product.title}
        </Heading>
        {Boolean(compareAtPrice && !hidePrice) && <CompareAtPrice price={String(compareAtPrice)} />}
        {!hidePrice && (
          <Text
            variant="small"
            as="p"
            sx={{
              color: 'balticSea',
              m: 0,
            }}
          >
            {priceLabel}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default ProductItemSecondary
