import React, { FC, MutableRefObject, useEffect, useMemo, useState } from 'react'
import { Maybe, ProductItemComponentType, ProductVariantType } from '~/@types/models'
import ProductFieldCarousel from '~/components/product-field-carousel'
import ProductItemSecondary from '~/components/product-item-secondary'
import Section from '~/components/section'
import { space } from '~/gatsby-plugin-theme-ui'
import { withPrefix } from 'gatsby'

type Props = {
  product: ProductItemComponentType
  productVariant: ProductVariantType
  orderedArray: any[] | Maybe<any[]>
  affixTileScroll?: MutableRefObject<HTMLDivElement | null>
  trackLocation?: string
}

type CollectionData = {
  data: ProductItemComponentType[]
}

/**
 * fetch the collection data dynamically for re-rendering these items. uses a side effect to generate these files.
 * @param handle string that indicates a json file in the data dir
 * @returns
 */
async function fetchCollectionData<T extends CollectionData>(handle: string): Promise<T> {
  const path = withPrefix(`/collection-data/${handle}.json`)
  const res = await fetch(path)
  const json = await res.json()
  return json as T
}

const isRug = (tags: readonly string[]) => tags && tags.includes('product-rug')

/**
 * a separate grid of items that are used for "Pairs Well With" or "Complete The Look".
 */
export const ProductSubCollection: FC<Props> = props => {
  const { product, productVariant, affixTileScroll, trackLocation } = props

  const sortedArray = product.pairsWellWith.sort(function (a, b) {
    return (
      product.metafields.pairsWellWith.indexOf(a.handle) -
      product.metafields.pairsWellWith.indexOf(b.handle)
    )
  })

  const [fetched, setFetched] = useState(false)

  const [collection, setCollection] = useState<CollectionData>({
    data: sortedArray as any, // very hacky typing going on here that is difficult to rectify
  })

  const selectedOption = useMemo(() => {
    const colorOptions = productVariant?.selectedOptions?.find(
      option => option.name === 'Color' || option.name === 'Finish'
    )
    return colorOptions && colorOptions.value
  }, [productVariant])

  useEffect(() => {
    if (!fetched && !collection.data.length) {
      fetchCollectionData(product.handle).then(response => {
        if (response.data) {
          setCollection(response)
        }
        setFetched(true)
      })
    }
  }, [product.handle])

  return (
    <Section
      affixTileScroll={affixTileScroll}
      label={isRug(product.tags) ? 'PAIRS WELL WITH' : 'COMPLETE THE LOOK'}
      sxSection={{
        pt: '0 !important',
        mb: '40px !important',
      }}
      sxLabel={{
        mb: '24px !important',
        fontSize: ['20px !important', '28px !important'],
        color: '#2b2b2b',
        fontWeight: 500,
      }}
    >
      <ProductFieldCarousel
        data={collection.data.map(product => ({
          product: {
            ...product,
            hidePrice: true,
          },
        }))}
        GridItem={ProductItemSecondary}
        carouselOnMobile={true}
        gridItemProps={{ columns: [1, 2, null, 3, 3], gap: [space.gridGap3] }}
        selectedOption={selectedOption}
      />
    </Section>
  )
}
