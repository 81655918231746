/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'

import { ColorBoxInner } from './colorBoxInner'
import { SystemStyleObject } from '@styled-system/css'

interface ColorBoxProps {
  className?: string
  id?: string
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onClick?: () => void
  src: string
  alt?: string
  sx?: SystemStyleObject
  selectedValue?: string
  value: string
  currentlyNotInStock?: boolean
  showTooltip?: boolean
}

export const ColorBoxV2 = ({
  className,
  sx,
  selectedValue,
  currentlyNotInStock,
  value,
  showTooltip = true,
  ...props
}: ColorBoxProps): JSX.Element => {
  const currentColor = value === selectedValue ? '#000' : '#DCDCDC'
  return (
    <Box
      sx={{
        '&:hover': {
          '.tooltip': {
            display: 'block',
          },
        },
      }}
    >
      <ColorBoxInner
        className={`color-box${className ? ' ' + className : ''}`}
        id={`${String(value)}`}
        data-selected={value === selectedValue}
        defaultSx={{
          position: 'relative',
          overflow: 'hidden',
          margin: '0',
          border: `1px solid ${value === selectedValue ? '#000' : 'transparent'}`,
          padding: '3px',
          ...sx,
          '&::after': {
            content: '""',
            display: currentlyNotInStock ? 'block' : 'none',
            position: 'absolute',
            zIndex: '1',
            opacity: '1',
            background: `linear-gradient( to bottom right, transparent calc(50% - 1px), ${currentColor} calc(50% - 1px), ${currentColor} 50%, transparent 50% )`,
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
          },
        }}
        alt={String(value)}
        {...props}
      />
      {showTooltip && (
        <Text
          className="tooltip"
          sx={{
            color: '#585858',
            display: 'none',
            position: 'absolute',
            left: 0,
            zIndex: 1,
            top: -30,
            padding: '5px 10px',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
            backgroundColor: '#fff',
            width: '100%',
          }}
        >
          {value}
        </Text>
      )}
    </Box>
  )
}
